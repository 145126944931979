import React, { useEffect, useRef } from "react";
import { MTClientAuthTokenGenCallback, MTCreateCartOAuthCallback } from "../../helpers/MtApi";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
const MtLogin = () => {
  const location = useLocation();
  const hasRunCallback = useRef(false);

  const oauthCallbackFunc = async () => {
    // if (hasRunCallback.current) return;
    // hasRunCallback.current = true;

    try {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      queryParams.delete("code");
      if (!code) return false;

      // Get the token from cookies
      const cookieName = `mt.token.https%3A%2F%2Fsynx.sandbox.marianaiframes.com`;
      const mtToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith(cookieName))
        ?.split("=")[1];

      //   if (!mtToken) {
      //     console.error("MT token not found in cookies");
      //     return;
      //   }

      const ghlAuthTokenGenCallbackResult = await MTClientAuthTokenGenCallback({
        code,
        tenant: "synx.sandbox",
        mtToken: decodeURIComponent(mtToken), // Decode the URL-encoded cookie value
      });
      console.log(ghlAuthTokenGenCallbackResult);
    } catch (error) {
      console.log(error);
    } finally {
      try {
        // Get fresh token in case it was updated
        const cookieName = `mt.token.https%3A%2F%2Fsynx.sandbox.marianaiframes.com`;
        const mtToken = document.cookie
          .split("; ")
          .find((row) => row.startsWith(cookieName))
          ?.split("=")[1];

        const createCartOAuthCallbackResult = await MTCreateCartOAuthCallback({
          tenant: "synx.sandbox",
          partnerId: "41368",
          productId: "14720",
          locationId: "48723",
          mtToken: decodeURIComponent(mtToken), // Decode the URL-encoded cookie value
        });
        console.log(createCartOAuthCallbackResult);
        window.location.href = "/membership/purchase/buy";
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    oauthCallbackFunc();
  }, []);

  return (
    <div className="h-screen bg-gray-200 flex flex-col">
      <nav className="bg-white shadow-md p-4 flex items-center justify-center">
        <span className="text-4xl font-bold text-orange-600">SYNX</span>
      </nav>
      {/* <div data-mariana-integrations="/account?login=true"></div> */}
      <div className="flex-1 flex items-center justify-center">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default MtLogin;
