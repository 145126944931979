import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";

const clientId = window.location.hostname === "localhost" ? "8fkcgxKGYDd3vA75bYO6K1CIng2tM1bcE9HPK39N" : "BwCKvs9ZrDMVGop0FcsQ1qQb6Y8rgTdG6jYmYTYz";
const redirectUri = window.location.hostname === "localhost" ? "http://localhost:3000/membership/purchase/account/login" : "https://app.synx.ai/membership/purchase/account/login";
const state = "8fkcgxKGYDd3vA75bYO";

const MtPurchase = () => {
  const [redeemClicked, setRedeemClicked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const tenant = urlParams.get("tenant") || "synx.sandbox";

  useEffect(() => {
    // Check for the authentication cookie
    const cookieName = `mt.is.authenticated.https%3A%2F%2F${tenant}.marianaiframes.com`;
    const cookies = document.cookie.split(";");
    const isAuth = cookies.some((cookie) => cookie.trim().startsWith(`${cookieName}=true`));
    setIsAuthenticated(isAuth);
  }, [tenant]);

  const handleRedeem = () => {
    setRedeemClicked(true);
  };

  const handleSignIn = () => {
    window.location.href = `https://${tenant}.marianatek.com/o/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
  };

  const handleLogout = () => {
    window.location.href = "/membership/purchase/account/create";
  };

  return (
    <div className="h-screen bg-gray-200 flex flex-col">
      <nav className="bg-white shadow-md p-4 flex items-center justify-between">
        <div></div>
        <span className="text-4xl font-bold text-orange-600">SYNX</span>
        {isAuthenticated && (
          <button onClick={handleLogout} className="text-gray-600 hover:text-gray-800 px-4 py-2 rounded-md transition flex items-center gap-2">
            <FiLogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        )}
        {!isAuthenticated && <div></div>}
      </nav>

      <div className="flex-1 flex items-center justify-center">
        <div className="w-full max-w-lg bg-white shadow-md rounded-lg p-8 text-center">
          {/* <h1 className="text-2xl font-bold mb-4 text-orange-600">SYNX SANDBOX</h1> */}
          <h2 className="text-2xl font-semibold">Yearly Membership Offer ($2000 Value)</h2>
          <p className="text-gray-700 mt-2">3 personal training sessions + 1 complimentary training session. Offer valid for training sessions with non-lead trainer.</p>
          <div className="flex items-center justify-center gap-3 mt-4">
            <span className="text-2xl font-bold text-gray-400 line-through">US$2000.00</span>
            <span className="text-2xl font-bold text-green-600">US$1500.00</span>
          </div>
          {redeemClicked ? (
            <div className="flex flex-col ">
              {/* <button className="w-full bg-black text-white py-3 mt-4 rounded-md hover:bg-gray-700 transition" onClick={handleSignIn}>
                Sign In
              </button>
              <button className="w-full bg-gray-300 text-black py-3 mt-4 rounded-md hover:bg-gray-200 transition" onClick={() => setCreateAccountClicked(true)}>
                Create Account
              </button> */}
              {/* <a data-mariana-auth-link href="/membership/purchase/account/login" className="w-full bg-black text-white py-3 mt-4 rounded-md hover:bg-gray-700 transition">
                Sign In
              </a> */}
              <button className="w-full bg-green-700 text-white py-3 mt-4 rounded-md hover:bg-green-800 transition" onClick={handleSignIn}>
                Sign In
              </button>
              <a data-mariana-auth-link href="/membership/purchase/account/create" className="w-full bg-gray-300 text-black py-3 mt-4 rounded-md hover:bg-gray-200 transition">
                Create Account
              </a>
              {/* <div data-mariana-integrations="/account"></div> */}
            </div>
          ) : (
            <div>
              <button className="w-full bg-black text-white py-3 mt-6 rounded-md hover:bg-gray-700 transition" onClick={handleRedeem}>
                Redeem Offer
              </button>
              <div className="text-gray-500 text-sm mt-6 border-t pt-4">
                Need help? Email us at{" "}
                <a href="mailto:nystudio@pvolve.com" className="text-blue-500 underline">
                  support@synx.ai
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MtPurchase;
