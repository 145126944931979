import React, { useEffect, useState } from "react";

const MtRegister = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tenant = urlParams.get("tenant") || "synx.sandbox";
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    // Check for the authentication cookie
    const cookieName = `mt.is.authenticated.https%3A%2F%2F${tenant}.marianaiframes.com`;
    const cookies = document.cookie.split(";");
    const isAuth = cookies.some((cookie) => cookie.trim().startsWith(`${cookieName}=true`));
    setIsAuthenticated(isAuth);
  }, [tenant]);
  useEffect(() => {
    // Define MT_CONFIG in the global scope
    window.MT_CONFIG = {
      events: {
        onLoginComplete: function (data) {
          console.log("Login complete event triggered", data);
          if (data && (data.emailAddress || data.userId)) {
            window.location.href = "/membership/purchase/account/login";
          }
        },
        onCreateAccountComplete: function (data) {
          console.log("Create account event triggered", data);
          if (data && (data.emailAddress || data.userId)) {
            window.location.href = "/membership/purchase/account/login";
          }
        },
      },
    };

    // Ensure the config is properly set
    console.log("MT_CONFIG initialized:", window.MT_CONFIG);

    // Backup method: Listen for messages from iframe
    window.addEventListener("message", function (event) {
      console.log("Message received:", event.data);
      // Verify the origin is from your Mariana domain
      if (event.origin.includes("marianaiframes.com")) {
        if (event.data.type === "loginComplete" || event.data.type === "createAccountComplete") {
          window.location.href = "/membership/purchase/account/login";
        }
      }
    });

    return () => {
      // Cleanup listener on unmount
      window.removeEventListener("message", function () {});
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="h-screen bg-gray-200 flex flex-col">
      <nav className="bg-white shadow-md p-4 flex items-center justify-center">
        <span className="text-4xl font-bold text-orange-600">SYNX</span>
      </nav>

      <div className="flex-1">
        <noscript>
          Please enable JavaScript to view the{" "}
          <a href="https://marianatek.com/?ref_noscript" rel="nofollow">
            Web Integrations by Mariana Tek.
          </a>
        </noscript>
        {/* {isAuthenticated ? <div data-mariana-integrations="/account/create?logout=true"></div> : <div data-mariana-integrations="/account/create"></div>} */}
        <div data-mariana-integrations="/account/create?logout=true"></div>
      </div>
    </div>
  );
};

export default MtRegister;
