import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";

const MtBuypage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const tenant = urlParams.get("tenant") || "synx.sandbox";

  useEffect(() => {
    // Check for the authentication cookie
    const cookieName = `mt.is.authenticated.https%3A%2F%2F${tenant}.marianaiframes.com`;
    const cookies = document.cookie.split(";");
    const isAuth = cookies.some((cookie) => cookie.trim().startsWith(`${cookieName}=true`));
    setIsAuthenticated(isAuth);
  }, [tenant]);

  useEffect(() => {
    // Define MT_CONFIG in the global scope
    window.MT_CONFIG = {
      events: {
        onCheckoutComplete: ({ order, userId }) => {
          window.location.href = "https://synx.ai/confirmation";
        },
      },
    };
  }, []);

  const handleLogout = () => {
    window.location.href = "/membership/purchase/account/create";
  };

  return (
    <div className="h-screen bg-gray-200 flex flex-col">
      <nav className="bg-white shadow-md p-4 flex items-center justify-between">
        <div></div>
        <span className="text-4xl font-bold text-orange-600">SYNX</span>
        {isAuthenticated && (
          <button onClick={handleLogout} className="text-gray-600 hover:text-gray-800 px-4 py-2 rounded-md transition flex items-center gap-2">
            <FiLogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        )}
        {!isAuthenticated && <div></div>}
      </nav>

      <div className="flex-1">
        {/* <div data-mariana-integrations="/buy"></div> */}
        {/* <div data-mariana-integrations="/account?login=true"></div> */}
        <div data-mariana-integrations="/checkout/48723?login=true" className="bg-gray-200"></div>
      </div>
    </div>
  );
};

export default MtBuypage;
